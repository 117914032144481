import React, { FC, PropsWithChildren } from 'react';
import { MetaData } from '@router/router.types';
import { SvgSprite } from '../../SvgSprite/SvgSprite';
import { Header } from '../../Header/Header';
import styles from './LayoutStart.module.scss';

export const LayoutStart: FC<PropsWithChildren<{ meta?: MetaData }>> = ({ children }) => {
	return (
		<>
			<SvgSprite />
			<Header/>
			<div className={styles.pageWrap}>
				{children}
			</div>
		</>
	);
};
