import React, { PropsWithChildren, ComponentPropsWithoutRef } from 'react';
import classnames from 'classnames';

import './FormControl.scss';
import { Icon } from '@src/gui';

interface FormControlProps {
	type: 'radio' | 'checkbox';
	name: string;
	value?: string | number;
	disabled?: boolean;
	className?: string;
	checked?: boolean;
}

export const FormControl = React.forwardRef<
	HTMLInputElement,
	PropsWithChildren<FormControlProps & ComponentPropsWithoutRef<'input'>>
>(
	(
		{ type = 'checkbox', name, value, disabled = false, className, children, checked, ...props },
		forwardedRef
	) => {

		return (
			<label className={classnames('form-control', className)}>
				<input
					{...props}
					type={type}
					name={name}
					value={value}
					disabled={disabled}
					ref={forwardedRef}
					checked={checked}
					className={'validatedItem'}
				/>
				{children && <span dangerouslySetInnerHTML={{__html: children.toString()}}/>}
				{type === 'checkbox' && <Icon id="checkmark" />}
			</label>
		);
	}
);
