import axios, { AxiosError } from 'axios';
import { camelCase, snakeCase } from 'lodash';
import { deeplyApplyKeyTransform } from '@utils';
import { getHeaders, getUrl } from '@utils';
import { toFormData } from '@utils';
import MessageStore from '@store/Message';

interface FetchConfig {
	url: string;
	method?: 'OPTIONS' | 'GET' | 'POST' | 'PUT' | 'PATCH';
	headers?: Headers;
	body?: any;
}

const transformErrorData = (data?: Record<string, string>) => {
	if (!data) return data;

	return Object.entries(data).map((entry) => {
		return `Поле "${entry[0]}": ${entry[1]}.`.replace(/(\.+)$/, '.');
	});
};

const handleError = (error: AxiosError) => {
	const errorText =
		error?.response?.data?.detail ||
		(error?.response?.status === 404
			? 'Эндпоинт не найден, проверьте запрос к API'
			: transformErrorData(error?.response?.data) || 'Неизвестная ошибка');

	const message = {
		title: 'Произошла ошибка',
		text: errorText,
		isError: true,
		showButton: false,
	};

	MessageStore.setNotice(message);

	throw error;
};

export const fetcher = <Model>(args: FetchConfig | string) => {
	const { url, method = 'GET', headers = new Headers({}), body = undefined } =
		typeof args === 'string' ? { url: args } : args;

	let data = body ? deeplyApplyKeyTransform<Record<string, any>>(body, snakeCase) : body;

	if (body && headers?.get('Content-Type') === 'multipart/form-data') {
		data = toFormData(body);
	}

	return axios({
		method: method,
		url: getUrl(url),
		headers: headers === null ? {} : getHeaders(headers),
		data: data,
	})
		.then((response) => {
			if (typeof response.data === 'undefined') throw response;
			return deeplyApplyKeyTransform<Model>(response.data, camelCase);
		})
		.catch((error) => {
			if (error.response){
				return error.response.data;
			}
			if (error.response.status !== 401) {
				return handleError(error);
			}

			return error;
		});
};
