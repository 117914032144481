import { configure, makeObservable, observable, action } from 'mobx';

configure({
	useProxies: 'ifavailable',
});

export type HashStoreNames = 'hash';
type Store = Record<HashStoreNames, string>;

class HashStore {
	@observable store: Store = {
		hash: '',
	};

	constructor() {
		makeObservable(this);
	}

	@action
	setHashState(name: HashStoreNames, state: string) {
		this.store[name] = state;
	}
}

export default new HashStore();
