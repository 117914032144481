import { configure, makeObservable, observable, action } from 'mobx';

configure({
	useProxies: 'ifavailable',
});

export type PopUpsNames = 'notices';
type Store = Record<PopUpsNames, boolean>;

class Popups {
	@observable store: Store = {
		notices: false,
	};

	constructor() {
		makeObservable(this);
	}

	@action
	setPopupState(name: PopUpsNames, state: boolean) {
		this.store[name] = state;
	}
}

export default new Popups();
