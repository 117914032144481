import axios, { AxiosResponse } from 'axios';
import { getJWT, setJWT } from '@src/utils';

const { NODE_ENV, REACT_APP_API_URL, REACT_APP_DEV_TOKEN} = process.env as Record<string, string>;

export const withoutTrailingSlash = (url: string) => url.replace(/\/$/, '');
export const withoutLeadingSlash = (url: string) => url.replace(/^\//, '');

export const concatUrls = (base: string, url: string) => {
	base = withoutTrailingSlash(base);
	url = withoutLeadingSlash(url);

	if (url.match(base)) return url;

	return `${base}/${url}`;
};

export const getUrl = (url: string): string =>
	`${withoutTrailingSlash(REACT_APP_API_URL)}/${withoutLeadingSlash(url)}`;
// NODE_ENV === 'development'
// 	? `${withoutTrailingSlash(REACT_APP_API_URL_DEV)}/${withoutLeadingSlash(url)}`
// 	: `${withoutTrailingSlash(REACT_APP_API_URL_PROD)}/${withoutLeadingSlash(url)}`;

export const getHeaders = (headers: Headers): Record<string, string> => {

	if (NODE_ENV === 'development' && REACT_APP_DEV_TOKEN) {
		headers.set('Authorization', `Token ${REACT_APP_DEV_TOKEN}`);
	}

	headers.set('content-type', `application/json`);

	return Object.fromEntries(headers.entries());
};

export const refreshToken = (): Promise<AxiosResponse> => {
	const refresh = getJWT('refresh');

	const isAdmin = localStorage.getItem('isAdmin');
	const isAdfs = localStorage.getItem('isAdfs');

	const refreshUrl = isAdmin || isAdfs ? 'jwt/auth/refresh/' : 'auth/refresh/';

	return axios({
		method: 'POST',
		url: getUrl(refreshUrl),
		data: { refresh: refresh },
	}).then((response) => {
		try {
			setJWT({
				access: response.data.access,
			});
			return response;
		} catch (e) {
			throw response;
		}
	});
};

export * from './jwt';
export * from './user';
