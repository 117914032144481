import React, {FC, Fragment, PropsWithChildren, useCallback} from 'react';
import  './Stepper.scss';
import {Skeleton} from "@src/gui";
import Steppers from "@store/Stepper";
import classnames from 'classnames';

interface StepperProps {
	activeQuestion: number,
	questionsArray: Array<any>;
	isLoading?: boolean;
}

/*
классы для step:
completed - завершен
active - текущий
disabled - пока не ответили
 */


export const Stepper: FC<PropsWithChildren<StepperProps>> = React.memo(
		({ activeQuestion, questionsArray, isLoading }) => {

			const changeStep = useCallback((e) => {
				Steppers.setStepperState('step', parseInt(e.target.dataset.id) - 1);
			}, []);

			if (isLoading) {
				return (
						<div className="stepper">
							<div className="stepper__wrap">
								<div className="stepper__step active">
									<div className="stepper__number">
										<Skeleton display="block" width="48px" height="48px"/>
									</div>
									<Skeleton display="inline-block" width="100px" height="40px"/>
								</div>
								<div className="stepper__line"></div>
								<div className="stepper__step ">
									<div className="stepper__number">
										<Skeleton display="inline-block" width="48px" height="48px"/>
									</div>
								</div>
								<div className="stepper__step active">
									<div className="stepper__number">
										<Skeleton display="inline-block" width="48px" height="48px"/>
									</div>
								</div>
							</div>
						</div>
				);
			}

			return (
				<div className="stepper">
					<div className="stepper__wrap">
						{questionsArray.map((section) => (
							<Fragment key={section.id}>
								<div
									className={classnames('stepper__step',
										activeQuestion === section.id && 'active' ,
										activeQuestion > section.id && 'completed',
										activeQuestion < section.id && 'disabled',
										section.id === questionsArray.length && 'last')}>
									<button onClick={changeStep} className="stepper__number">
										<svg className="stepper__icon">
											<use xlinkHref="#step-done"></use>
										</svg>
										<span data-id={section.id} className="stepper__circle">{section.id}</span>
									</button>
									<div className="stepper__textWrap">
										<p className="stepper__title">{section.title}</p>
										<p className="stepper__amount">{
											section.children.length === 1
													? '1 вопрос'
													: section.children.length < 5
															? section.children.length + ' вопроса'
															: section.children.length + ' вопросов'
										}</p>
									</div>
								</div>
								{
									section.id === questionsArray.length
										? <div className="stepper__line last"></div>
										: <div className="stepper__line"></div>
								}
							</Fragment>
							))
						}
					</div>
				</div>
			);
		}
);
