/**
 * R-PHARM API
 * Your project description
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export enum NotificationTypeEnum {
	NUMBER_0 = 0,
	NUMBER_1 = 1,
	NUMBER_2 = 2,
	NUMBER_3 = 3,
	NUMBER_4 = 4,
	NUMBER_5 = 5,
	NUMBER_6 = 6,
	NUMBER_7 = 7,
	NUMBER_8 = 8,
	NUMBER_9 = 9,
	NUMBER_10 = 10,
	NUMBER_11 = 11,
	NUMBER_12 = 12,
	NUMBER_13 = 13,
}
