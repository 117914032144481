import React, { FC, useCallback } from 'react';
import { Icon } from '@src/gui';
import classnames from 'classnames';

import './CloseButton.scss';

interface Props {
	onClick: (e: MouseEvent) => void;
	className?: string;
	label?: string;
}

export const CloseButton: FC<Props & Partial<HTMLButtonElement>> = ({
	onClick,
	className,
	label = 'Закрыть модальное окно',
}) => {
	const clickHandler = useCallback(
		(e) => {
			onClick(e);
		},
		[onClick]
	);

	return (
		<button
			className={classnames('close-button', className, 'hidden-print')}
			type="button"
			onClick={clickHandler}
			aria-label={label}>
			<Icon id="close" />
		</button>
	);
};
