import { Answer, Section, QuestionExtended } from '@models';
import { useMemo } from 'react';
import { useAnswerList, useQuestionList } from '@api';
import { mapToArray } from '@utils';

export interface SectionWithQuestions extends Section {
	children: Array<QuestionExtended>;
}

interface ArgumentsType {
	questionnairesId?: string;
}

interface ReturnData {
	data: Array<SectionWithQuestions>;
	mock: Array<SectionWithQuestions>;
	isLoading: boolean;
	isError: boolean;
}

const assignAnswerToQuestion = (question: QuestionExtended, answers: Array<Answer>): void => {
	question.answer = answers.find((answer) => answer?.question?.id === question.id);

	if (question?.children?.length) {
		for (const child of question.children) {
			assignAnswerToQuestion(child, answers);
		}
	}
};

const getQuestionsTree = (
	questions: Array<QuestionExtended>,
	answers: Array<Answer>
): Array<SectionWithQuestions> => {
	const sections = new Map();

	questions = questions.sort((a, b) => Number(a.position) - Number(b.position));

	for (const question of questions) {
		const current = sections.get(question.section.id);

		assignAnswerToQuestion(question, answers);

		if (current) {
			current.children.push(question);
		} else {
			sections.set(question.section.id, {
				...question.section,
				children: [question],
			});
		}
	}

	return mapToArray(sections);
};

export const useQuestionTree = ({ questionnairesId }: ArgumentsType): ReturnData => {
	const mock = useMemo(getMock, []);

	const {
		data: questionList,
		isLoading: questionIsLoading,
		isError: questionIsError,
	} = useQuestionList();
	const { data: answersList, isLoading: answersIsLoading, isError: answersIsError } = useAnswerList(
		{
			parent_lookup_questionnaire: questionnairesId,
		}
	);

	const tree = useMemo(
		() =>
			questionList?.results && answersList?.results
				? getQuestionsTree(questionList.results as Array<QuestionExtended>, answersList.results)
				: [],
		[questionList, answersList]
	);

	return {
		data: tree,
		mock: mock,
		isLoading: questionIsLoading || answersIsLoading,
		isError: questionIsError || answersIsError,
	};
};

const getMock = (count: number = 2) => {
	const section: SectionWithQuestions = {
		declarationType: 0,
		id: 0,
		position: 0,
		title: '',
		children: [],
	};
	const question: QuestionExtended = {
		id: 0,
		text: '',
		position: '',
		section: {
			id: 0,
			title: '',
			position: 0,
			declarationType: 0,
		},
		children: [],
	};
	const answer = {
		id: '',
		answer: false,
		text: '',
		createdAt: '',
		updatedTo: '',
		declaration: '',
	};

	const result: Array<SectionWithQuestions> = [];

	for (let i = 0; i < count; i++) {
		result.push({
			...section,
			id: i,
			children: [
				{
					...question,
					answer: {
						...answer,
						id: `q-${i}-a-1`,
						question,
					},
					children: [
						{
							...question,
							answer: {
								...answer,
								id: `q-${i}-a-2`,
								question,
							},
						},
						{
							...question,
							answer: {
								...answer,
								id: `q-${i}-a-3`,
								question,
							},
						},
					],
				},
				{
					...question,
					answer: {
						...answer,
						id: `q-${i}-a-4`,
						question,
					},
				},
			],
		});
	}

	return result;
};
