import React, { ComponentPropsWithoutRef, useEffect, useState, useCallback, useRef } from 'react';
import classnames from 'classnames';

import './Input.scss';

interface TextAreaInterface extends ComponentPropsWithoutRef<'textarea'> {
	label?: string;
	labelPlaceholder?: boolean;
	defaultValue?: string;
	hasError?: boolean;
	isLoading?: boolean;
	size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
	autoGrow?: boolean;
	mixedQuestion?:boolean;
}

//TODO: autosize

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaInterface>(
	(
		{
			label,
			labelPlaceholder,
			defaultValue = '',
			className,
			hasError,
			isLoading,
			size,
			autoGrow,
			mixedQuestion,
			...props
		}: TextAreaInterface,
		forwardedRef
	) => {

		const inputNestedRef =  useRef<HTMLTextAreaElement | null>(null);
		const [wrap, setWrap] = useState<HTMLDivElement | null>(null);
		const [inputValue, setInputValue] = useState(defaultValue);
		const [inputFocused, setInputFocused] = useState(false);
		const [transitionClass, setTransitionClass] = useState(false);
		const [placeholderActive, setPlaceholderActive] = useState(false);
		const [error, setError] = useState(false);

		useEffect(() => {
			inputNestedRef.current = wrap?.querySelector('textarea') || null;
		}, [wrap])

		const handleFocus = useCallback(
			(e) => {
				setInputFocused(true);
				props?.onFocus && props.onFocus(e);
				setError(false)
			},
			[props]
		);

		const handleBlur = useCallback(
			(e) => {
				setInputFocused(false);
				props?.onBlur && props.onBlur(e);
				let string = e.target.value
				let test = string.replaceAll(' ', '')

				if ((string.length === 0 || test.length === 0) ) {
					setError(true)
				}
			},
			[props, mixedQuestion]
		);
		const handleChange = useCallback(
			(e) => {
				setInputValue(inputNestedRef.current?.value || '');
				props?.onChange && props.onChange(e);
			},
			[props]
		);

		useEffect(() => {
			if (labelPlaceholder) setTransitionClass(true);
			// eslint-disable-next-line
		}, []);

		useEffect(() => {
			if (labelPlaceholder) {
				setPlaceholderActive(inputValue.length > 0 || inputFocused);
			}
		}, [labelPlaceholder, inputFocused, inputValue]);

		return (
			<label className={classnames('input-wrapper', { 'has-error': error })}>
				{label && (
					<div
						className={classnames('input-wrapper__label', {
							'is-placeholder': labelPlaceholder,
							'is-active': placeholderActive,
							'has-transition': transitionClass,
						})}
					>
						{label}
					</div>
				)}
				<div
					className={classnames(
						'input-wrapper__field',
						{
							'input-wrapper__field--autoGrow': autoGrow,
							disabled: props.disabled,
						},
						size
					)}
					data-replicated={inputValue}
					ref={setWrap}
				>
					<textarea
						{...props}
						ref={forwardedRef}
						className={classnames(className, size)}
						onFocus={handleFocus}
						onBlur={handleBlur}
						onChange={handleChange}
						defaultValue={defaultValue}
						maxLength={3000}
					/>
				</div>
			</label>
		);
	}
);
