import React, { FC, PropsWithChildren, memo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form/dist/index.ie11';
import { TextArea } from '@src/gui/Form';

interface AnswerTextProps {
	name: string;
	position: string;
	value?: string;
	hasError?: boolean;
	description?: string;
	isDisabled?: boolean;
	placeholder?: string;
	mixedQuestion?: boolean;
	parent?: string;
}

export const AnswerText: FC<PropsWithChildren<AnswerTextProps>> = memo(
	({ name, position, value, hasError, isDisabled, placeholder, mixedQuestion, parent }) => {
		const { register, unregister, clearErrors } = useFormContext();

		useEffect(() => {
			setTimeout(() => {
				clearErrors(name);
			}, 1000);
			// eslint-disable-next-line
		}, [clearErrors]);

		useEffect(() => {
			return () => unregister(name);
		}, [unregister, name]);


		return (
			<div className="answer__text">
				<TextArea
					className={'validatedItem'}
					placeholder={placeholder}
					disabled={isDisabled}
					hasError={hasError}
					autoGrow={true}
					name={name}
					data-parent={parent}
					ref={register({
						required: {
							value: true,
							message: position,
						},
					})}
					mixedQuestion={mixedQuestion}
					defaultValue={value || ''}
				/>
				<small className={"errorText"}><span>Укажите причину</span></small>
			</div>
		);
	}
);
