export const createRandomGetter = <T>(array: T[]): (() => T) => {
	let copy = array.slice(0);

	return () => {
		if (copy.length < 1) {
			copy = array.slice(0);
		}

		const index = Math.floor(Math.random() * copy.length);
		const item = copy[index];

		copy.splice(index, 1);

		return item;
	};
};

export const mapToArray = <K, V>(map: Map<K, V>) => {
	const array: Array<V | undefined> = [];

	Array.from(map.keys()).forEach((key) => {
		array.push(map.get(key));
	});

	return array;
};
