/**
 * R-PHARM API
 * Your project description
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export enum Status268Enum {
	WF = 'WF',
	DR = 'DR',
	SB = 'SB',
}
