import { PatchedExitAnswer } from '@models';
import { isEmptyObject, mapToArray } from '@utils';

export const serializeAnswers = (
	data: Record<string, boolean | string | undefined>,
	declarationId: string
): Array<PatchedExitAnswer> => {
	if (!isEmptyObject(data)) {
		const serializedData = new Map();

		for (const fieldName in data) {
			if (data.hasOwnProperty(fieldName)) {
				const value = data[fieldName];

				const answerId = fieldName.replace('-text', '');
				const isTextField = !!fieldName.match('-text');

				if (value !== null) {
					if (isTextField) {
						serializedData.set(answerId, {
							...serializedData.get(answerId),
							answer: value,
						});
					} else {
						serializedData.set(answerId, {
							id: answerId,
							answer: value,
							declaration: declarationId,
						});
					}
				}
			}
		}

		return mapToArray(serializedData);
	} else {
		return [];
	}
};
