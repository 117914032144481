import React, { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';
import { Skeleton } from '@src/gui';

import './Question.scss';

interface QuestionProps {
	name?: string;
	num?: number | string;
	text?: string;
	isNested?: boolean;
	hasError?: boolean;
	isLoading?: boolean;
	isDisabled?: boolean;
}

export const Question: FC<PropsWithChildren<QuestionProps>> = React.memo(
	({ name, num, text, isNested, hasError, isLoading, children }) => {

		if (isLoading) {
			return (
				<div className={classnames('question', isNested && 'is-nested')}>
					<div className="question__num">
						<Skeleton display="inline-block" width={20} />
					</div>
					<div className="question__text">
						<Skeleton display="inline-block" width="100%" />
						<Skeleton display="inline-block" width="80%" />
					</div>
					{children}
				</div>
			);
		}

		return (
			<div
				key={name}
				className={classnames('question', isNested && 'is-nested', hasError && 'has-error')}
				data-code={num}>
				<div className="question__wrap">
					<div className="question__num">{`${num}${!isNested ? `.` : ``}`}</div>
					{text && <div className="question__text" id={name} dangerouslySetInnerHTML={{__html: text}}/>}
				</div>
				{children}
			</div>
		);
	}
);
