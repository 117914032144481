import React, { FC } from 'react';

import './Spinner.scss';

interface SpinnerInterface {
	type: 'ring';
}

export const Spinner: FC<SpinnerInterface> = ({ type }) => {
	return (
		<div className="spinner">
			<div className={`spinner__${type}`} />
		</div>
	);
};
