import { configure, makeObservable, observable, action } from 'mobx';

configure({
	useProxies: 'ifavailable',
});

export interface MessageState {
	title: string | undefined;
	text: string | undefined;
	isError: boolean;
	showButton: boolean;
}

const initialState: MessageState = {
	title: undefined,
	text: undefined,
	isError: false,
	showButton: false,
};

class Message {
	@observable state: MessageState = initialState;
	@observable visible: boolean = false;

	constructor() {
		makeObservable(this);
	}

	@action
	setVisible(value: boolean) {
		this.visible = value;
	}

	@action
	setNotice(data: MessageState) {
		this.state = data;
		this.visible = true;
	}

	@action
	resetNotice() {
		this.state = initialState;
	}
}

export default new Message();
