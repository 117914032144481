import { configure, makeObservable, observable, action } from 'mobx';

configure({
	useProxies: 'ifavailable',
});

export type SteppersNames = 'step';
type Store = Record<SteppersNames, number>;

class Steppers {
	@observable store: Store = {
		step: 0,
	};

	constructor() {
		makeObservable(this);
	}

	@action
	setStepperState(name: SteppersNames, state: number) {
		this.store[name] = state;
	}
}

export default new Steppers();
