import React, {FC, useEffect, useRef, useState} from 'react';
import {Skeleton, Spacer} from '@src/gui';

import './IndexPage.scss';
import {observer} from "mobx-react-lite";
import {useAnswerList, useQuestionnary} from "@api";
import {InfoScreen} from "@components/InfoScreen/InfoScreen";
import {useMatch} from "react-router-dom";
import HashStore from "@store/HashStore";
import Steppers from "@store/Stepper";

interface IndexProps {
  isLoading?: boolean;
}

export const IndexPage: FC<IndexProps> = observer(
  ({isLoading}) => {
    const infoIdRef = useRef<string>('');
    const [id, setId] = useState<string | undefined>(undefined);

    const matchEntity = useMatch(':declarationId');
    const entity = matchEntity?.params?.declarationId;

    //отправляем на экран "ошибка"
    if (entity === undefined || entity.length < 65) infoIdRef.current = "errorSection";

    const questionsStart = useQuestionnary(id);
    const data = questionsStart.data
    const questIsLoading = questionsStart.isLoading

    let getSessionTime: boolean = true;

    // @ts-ignore
    if (data && data?.detail === 'Вы уже заполнили эту анкету') {
      infoIdRef.current = 'sessionComplete';
    }
    // @ts-ignore
    if (data && data?.detail === 'Анкета больше не доступна') {
      infoIdRef.current = 'sessionExpired';
    }

    useEffect(() => {
      setId((prev) => (entity ? entity : prev));
    }, [entity]);


    /////////// получить ответы чтобы перейти к определенному сразу
    const declarationId = data?.code;
    if (declarationId) HashStore.setHashState('hash', declarationId);

    const {
      data: answersList,
      isLoading: answersIsLoading,
      isError: answersIsError
    } = useAnswerList(
      {
        parent_lookup_questionnaire: declarationId,
      }
    );


    isLoading = isLoading || questIsLoading || answersIsLoading;


    if (getSessionTime) {
      let answerLength = 0;
      let firstNotAnswered = false;
      if (answersList?.results && !answersIsError) {
        answersList?.results.sort((a, b) => (a.question?.section.id ?? 0) - (b.question?.section.id ?? 0)).forEach((el) => {
          if (!el.answer && el.question?.section.id && !firstNotAnswered) {
            //отправляем на экран "вы уже начали заполнять" и открываем нужный шаг
            Steppers.setStepperState('step', el.question?.section.id - 1);
            infoIdRef.current = 'sessionStarted';
            firstNotAnswered = true;
          }

          if (el.answer) answerLength++
        })

        //отправляем на экран "вы еще не начали заполнять"
        if (answerLength === 0) {
          infoIdRef.current = 'sessionNotStarted'
        }
      }
    }


    if (isLoading) {
      return (
        <div className="wrap">
          <Skeleton display="block" width="300px" margin="auto" height="300px"/>
          <Spacer value={56}/>
          <Skeleton display="block" width="600px" margin="auto" height="60px"/>
          <Spacer value={21}/>
          <p className="description"/>
          <Skeleton display="block" width="500px" margin="auto" height="40px"/>
        </div>
      );
    }

    return <InfoScreen screen={infoIdRef.current}/>

  }
);

