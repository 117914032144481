import React, { FC, useEffect, useState, createContext, useContext, useMemo } from 'react';
import { isTouchDevice, getBpFlag, getScrollBarWidth, getVpHelpers } from '@utils';

interface ViewportInterface {
	vw: number;
	vh: number;
	sb: number;
	out: number;
	bp?: string;
}

const initialState = {
	vw: 0,
	vh: 0,
	sb: 0,
	out: 0,
	bp: undefined,
};
const isTouch = isTouchDevice();
const scrollbarWidth = getScrollBarWidth();
const [helper, helperContainer] = getVpHelpers();

export const ViewportContext = createContext<ViewportInterface>(initialState);
export const ViewportProvider: FC = ({ children }) => {
	const [state, setState] = useState<ViewportInterface>(initialState);

	useEffect(() => {
		let mounted = true;
		const isSSR = typeof window === 'undefined';

		if (isSSR || !mounted) return;

		const handleResize = () => {
			const vw = document.documentElement.clientWidth;
			const vh = isTouch && helper ? helper.offsetHeight : window.innerHeight;
			const bp = getBpFlag(vw);
			const sb = scrollbarWidth;
			const out = helperContainer?.offsetLeft;

			setState({
				vw,
				vh,
				sb,
				out,
				bp,
			});

			document.documentElement.style.setProperty('--vw', `${Math.max(320, vw)}px`);
			document.documentElement.style.setProperty('--vh', `${vh}px`);
			document.documentElement.style.setProperty('--ow', `${vw + sb}px`);
			document.documentElement.style.setProperty('--out', `${out}px`);
		};

		handleResize();
		window.addEventListener('load', handleResize, { passive: true });

		if (!isTouch) {
			window.addEventListener('resize', handleResize, { passive: true });
		} else {
			window.addEventListener('orientationchange', handleResize, { passive: true });
		}

		return () => {
			mounted = false;
			window.removeEventListener('load', handleResize);
			window.removeEventListener('resize', handleResize);
			window.removeEventListener('orientationchange', handleResize);
		};
	}, []);

	return (
		<ViewportContext.Provider value={state}>
			{useMemo(() => {
				return <>{children}</>;
			}, [children])}
		</ViewportContext.Provider>
	);
};

export const useViewport = (): ViewportInterface => {
	const context = useContext(ViewportContext);

	if (context === undefined) {
		throw new Error('useScroll was used outside of its Provider');
	}

	return context;
};
