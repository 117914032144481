import { NotificationTypeEnum } from '@models';
import React, { FC, PropsWithChildren, useMemo } from 'react';
import { IconId } from '@components/SvgSprite/SvgSprite';
import { Icon, Skeleton } from '@src/gui';

import './Notice.scss';

interface NoticeProps {
	type?: NotificationTypeEnum;
	isNew?: boolean;
	isLoading?: boolean;
}

export const Notice: FC<PropsWithChildren<NoticeProps>> = React.memo(
	({ type = 0, isNew, isLoading, children }) => {
		const icon: IconId = useMemo(() => {
			let icon = 'status-mail' as IconId;

			if (
				type === NotificationTypeEnum.NUMBER_7 ||
				type === NotificationTypeEnum.NUMBER_11 ||
				type === NotificationTypeEnum.NUMBER_12 ||
				type === NotificationTypeEnum.NUMBER_13
			) {
				icon = 'status-info';
			}

			return icon;
		}, [type]);

		return (
			<div className="notice">
				<div className="notice__icon">
					{isLoading && <Skeleton width={16} height={16} />}
					{!isLoading && <Icon id={icon} width={16} height={16} />}
					{isNew && <span className="notice__marker" />}
				</div>
				<div className="notice__text">
					{isLoading && (
						<>
							<Skeleton display="inline-block" width="100%" />
							<Skeleton display="inline-block" width="40%" />
						</>
					)}
					{!isLoading && children}
				</div>
			</div>
		);
	}
);
