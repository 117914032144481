import React, { FC, PropsWithChildren } from 'react';
import { MetaData } from '@router/router.types';
import { SvgSprite } from '../../SvgSprite/SvgSprite';
import { Header } from '../../Header/Header';

import styles from './LayoutForm.module.scss';

export const LayoutForm: FC<PropsWithChildren<{ meta?: MetaData }>> = ({ children }) => {
	return (
		<>
			<SvgSprite />
			<Header/>
			<div className={styles.pageWrap}>
				{children}
			</div>
		</>
	);
};
