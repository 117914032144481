import React, { Dispatch, RefObject, SetStateAction, useRef, useState } from 'react';
import {
	useForm,
	SubmitHandler,
	UseFormOptions,
	UseFormMethods,
} from 'react-hook-form/dist/index.ie11';

type SubmitStateType = boolean;
type BackendErrorsType = string | null;

interface ValidatableFormInterface<T> {
	config: UseFormOptions<T>;
	onSubmit?: SubmitHandler<T>;
}

interface ValidatableFormReturnInterface<T> {
	ref: RefObject<HTMLFormElement>;
	submitState: SubmitStateType;
	setSubmitState: Dispatch<SetStateAction<SubmitStateType>>;
	backendErrors: BackendErrorsType;
	setBackendErrors: Dispatch<SetStateAction<BackendErrorsType>>;
	methods: UseFormMethods<T>;
	submitHandler?: React.FormEventHandler<HTMLFormElement>;
}

export const useValidatableForm = <T>({
	onSubmit,
	config,
}: ValidatableFormInterface<T>): ValidatableFormReturnInterface<T> => {
	const ref = useRef<HTMLFormElement>(null);
	const [submitState, setSubmitState] = useState<SubmitStateType>(false);
	const [backendErrors, setBackendErrors] = useState<BackendErrorsType>(null);

	const methods = useForm<T>(config);
	const submitHandler = onSubmit && methods.handleSubmit(onSubmit);

	return {
		ref,
		submitState,
		setSubmitState,
		backendErrors,
		setBackendErrors,
		methods,
		submitHandler,
	};
};
