import useSWR from 'swr';
import { fetcher } from '@src/api/utils/fetcher';
import {Questionnaire} from '@models';
import {ReturnedSWRData} from "@api/hooks/types";


export const useQuestionnary =(id?: string): ReturnedSWRData<Questionnaire> => {
	const { data, error } = useSWR(
		id ? `/questionnaires/${id}/` : null,
		(url) => fetcher(url),
		{
			revalidateIfStale: false,
			revalidateOnFocus: true,
			revalidateOnReconnect: true,
		}
	);

	return {
		data: data,
		isLoading: !!id && !error && !data,
		isError: !id || !error,
	};
};
