import './QuestionnaryForm.scss';

import React, { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import classnames from 'classnames';
import { InfoScreen } from '@components/InfoScreen/InfoScreen';

import { FormProvider } from 'react-hook-form/dist/index.ie11';
import { Form } from '@src/gui/Form';
import { useQuestionTree, useValidatableForm } from '@hooks';
import { useAnswerPatch, useQuestionnaryPatch, serializeAnswers, useQuestionnary } from '@api';
import { mapToArray, throttle } from '@utils';

import { useMatch } from 'react-router-dom';
import HashStore from '@store/HashStore';

import { Stepper } from '@components/Stepper/Stepper';
import { Button, Icon, Skeleton, Spacer } from '@src/gui';
import Steppers from '@store/Stepper';
import { Status268Enum } from '@models';
import { Question } from '@components/Question/Question';
import { Answer } from '@components/Answer/Answer';

interface DeclarationFormProps {
	isLoading?: boolean;
}

//TODO: 404 при прямой ссылке
export const QuestionnaryForm: FC<DeclarationFormProps> = observer(({ isLoading }) => {
	const matchEntity = useMatch('questionnary/:questionnairesId');
	const entity = matchEntity?.params?.questionnairesId;

	if (entity === undefined) {
		return <InfoScreen screen="errorSection" />;
	}

	const questionnairesId = HashStore.store.hash ? HashStore.store.hash : entity;
	const { data: questionnaires, isLoading: quetionnaryIsLoading } =
		useQuestionnary(questionnairesId);

	let [currentQuestion, setCurrentQuestion] = useState(Steppers.store.step);
	let [animateClass, setAnimateClass] = useState('');
	let [nextQuestionIsAllowed, setNextQuestionIsAllowed] = useState(false);
	let [prevQuestionIsAllowed, setPrevQuestionIsAllowed] = useState(true);
	let [notAllAnswers, setNotAllAnswers] = useState(false);
	let [emptyMedia, setEmptyMedia] = useState(false);

	const {
		data: questionsTree,
		mock: questionsMock,
		isLoading: questionsIsLoading,
	} = useQuestionTree({
		questionnairesId,
	});

	isLoading = isLoading || questionsIsLoading || quetionnaryIsLoading;

	const questions = isLoading ? questionsMock : questionsTree;

	const { trigger: patchAnswers } = useAnswerPatch();
	const { trigger: patchQuestionnaires } = useQuestionnaryPatch();

	const { ref, methods } = useValidatableForm<Record<string, string>>({
		config: {
			mode: 'onChange',
			reValidateMode: 'onChange',
			criteriaMode: 'firstError',
			shouldFocusError: false,
			shouldUnregister: true,
		},
	});

	const validateAllMedia = useCallback(() => {
		if (!ref.current) return;

		const radioButtons = ref.current.querySelectorAll<HTMLInputElement>('input[type="radio"]');

		const allValid = Array.from(radioButtons).every((radio) => {
			const parent = radio.closest('.answer__radio');
			const inputBlock = parent?.querySelector('textarea');
			if (!inputBlock) return true;
			const value = inputBlock.value.replace(/^[\s]*$/, '').trim();
			return value.length > 0 || !radio.checked;
		});

		setEmptyMedia(!allValid);
	}, [ref]);

	const validate = useCallback(
		(radio: HTMLInputElement) => {
			setTimeout(validateAllMedia, 100);

			if (!ref.current) return;

			let parent = radio.closest('.answer__radio');
			if (!parent) return;

			let inputBlock = parent.querySelector('textarea');
			if (!inputBlock) return;
			inputBlock.focus();
			let test = inputBlock.value.replace(/^[\s]*$/, '').trim();

			if (inputBlock.value.length === 0 || test.length === 0) {
				setEmptyMedia(true);
			} else {
				setEmptyMedia(false);
			}
		},
		[ref, validateAllMedia]
	);

	const autoSaveToDraft = useCallback(
		(e) => {
			if (!questionnairesId || !questionnaires) return;

			validate(e.target);

			throttle(
				() => {
					const itemData: Record<string, string> = {};
					const itemName = e.target.name;

					const parentName = itemName.replace('-text', '');
					const parentNameMixed = itemName.replace('-mixed', '');
					let mixed = false;
					let mixedVal = [];

					const formData = methods.getValues();

					if (formData[itemName] !== undefined) {
						if (parentName !== itemName) {
							itemData[parentName] = formData[parentName];
						}

						if (parentNameMixed !== itemName) {
							mixed = true;
							itemData[parentNameMixed] = formData[parentNameMixed];

							const serializedData = new Map();

							if (e.target.type === 'radio') {
								return;
							} else {
								if (!e.target.value) return;
								if (e.target.value.replace(/^[\s]*$/, '').trim().length === 0) return;

								serializedData.set(parentNameMixed, {
									id: parentNameMixed,
									answer: e.target.dataset.parent,
									otherAnswer: e.target.value,
									declaration: questionnairesId,
								});
							}

							mixedVal = mapToArray(serializedData);
						}

						itemData[itemName] = formData[itemName];

						Promise.all([
							mixed
								? patchAnswers(questionnairesId, mixedVal)
								: patchAnswers(questionnairesId, serializeAnswers(itemData, questionnairesId)),
								patchQuestionnaires(questionnairesId, { status: Status268Enum.DR }),
						]).finally(() => {});
					}
				},
				200,
				'autoSaveToDraft'
			);
		},
		[questionnairesId, questionnaires, validate, methods, patchAnswers, patchQuestionnaires]
	);

	const IndexMinus = useCallback(() => {
		currentQuestion--;
		setPrevQuestionIsAllowed(false);

		setAnimateClass('fadeIn');
		setTimeout(() => {
			window.scrollTo(0, 0);
			setAnimateClass('fadeOut');
			setCurrentQuestion(currentQuestion);
			Steppers.setStepperState('step', currentQuestion);
			setPrevQuestionIsAllowed(true);
		}, 500);
	}, [currentQuestion]);

	const IndexPlus = useCallback(() => {
		if(currentQuestion === questions.length - 1)
		setNotAllAnswers(true);
		currentQuestion++;

		setAnimateClass('fadeIn');
		setTimeout(() => {
			window.scrollTo(0, 0);
			setAnimateClass('fadeOut');
			setCurrentQuestion(currentQuestion);
			Steppers.setStepperState('step', currentQuestion);
		}, 500);
	}, [currentQuestion]);

	useEffect(
		() => () => {
			Steppers.setStepperState('step', 0);
		},
		[]
	);

	useEffect(() => {
		if (isLoading) return;

		if (currentQuestion === questions.length && questionnairesId && questionnaires) {
			patchQuestionnaires(questionnairesId, { status: Status268Enum.SB }).then(() => {});
		}
		// eslint-disable-next-line
	}, [isLoading, currentQuestion]);

	useEffect(() => {
		setAnimateClass('fadeIn');
		setTimeout(() => {
			window.scrollTo(0, 0);
			setAnimateClass('fadeOut');
			setCurrentQuestion(Steppers.store.step);
		}, 500);
		// eslint-disable-next-line
	}, [Steppers.store.step]);

	useEffect(() => {
		if (!questions) return;

		const parent = questions[currentQuestion]?.children;

		if (parent) {
			setNotAllAnswers(false);

			const someQuestionsWithoutAnswers = parent?.some((question) => {
				// видимо, здесь не была обновлена схема,
				// поэтому тип у answer.answer: boolean | null,
				// а фактически может строкой приходить
				return (
					// @ts-ignore
					typeof question.answer?.answer?.length !== undefined &&
					// @ts-ignore
					question.answer?.answer?.length === 0
				);
			});

			if (someQuestionsWithoutAnswers) {
				setNotAllAnswers(true);
			}
		}

		// eslint-disable-next-line
	}, [questions, currentQuestion, Steppers.store.step]);

	useEffect(() => {
		setNextQuestionIsAllowed(!notAllAnswers && !emptyMedia);
	}, [emptyMedia, notAllAnswers]);


	// @ts-ignore
	if (questionnaires?.detail === 'Вы уже заполнили эту анкету') {
		return <InfoScreen screen="sessionComplete" />;
		// @ts-ignore
	} else if (questionnaires?.detail === 'Анкета больше не доступна') {
		return <InfoScreen screen="sessionExpired" />;
		// @ts-ignore
	} else if (questionnaires?.detail === 'Страница не найдена.') {
		return <InfoScreen screen="errorSection" />;
	}

	if (isLoading) {
		return (
			<div className={classnames(' form-container')}>
				<Spacer value={40} />
				<Skeleton display="inline-block" width="200px" height="40px" />
				<Spacer value={40} />
				<Skeleton display="block" width="100%" height="300px" />
				<Spacer value={24} />
				<Skeleton display="block" width="100%" height="100px" />
				<Spacer value={24} />
				<Skeleton display="block" width="100%" height="200px" />
			</div>
		);
	}


	return questions.length > 0 && !isLoading ? (
		currentQuestion < questions.length ? (
			<div className={classnames('declaration-form form-container')}>
				<Stepper
					activeQuestion={currentQuestion + 1}
					questionsArray={questions}
					isLoading={isLoading}
				/>

				<FormProvider {...methods}>
					<Form onSubmit={() => {}} onChange={autoSaveToDraft} ref={ref}>
						<div className={classnames('sectionWrap', animateClass)}>
							<Fragment key={`section-${questions[currentQuestion].id}`}>
								{questions[currentQuestion].children.map((question: any) => {
									const questionName = question?.answer?.id || '';

									return (
										<Question
											key={questionName}
											name={questionName}
											num={question.position}
											text={question.text}
											isLoading={isLoading}>
											<Answer
												name={questionName}
												question={question}
												answers={question.answers}
												isLoading={isLoading}
											/>
										</Question>
									);
								})}
							</Fragment>
						</div>

						{!isLoading && (
							<nav className="form-wrap">
								<div
									className={`${currentQuestion <= 0 ? 'hideBtn' : ''} ${
										prevQuestionIsAllowed ? '' : 'disabledBtn'
									}`}>
									<Button
										onClick={IndexMinus}
										type="button"
										size="md"
										design="green-icon"
										iconLeft={<Icon id="back" />}>
										Назад
									</Button>
								</div>
								<div className={nextQuestionIsAllowed ? '' : 'disabledBtn'}>
									<Button
										onClick={IndexPlus}
										type="button"
										size="md"
										design="green-icon"
										iconRight={<Icon id="forward" />}
										disabled={!nextQuestionIsAllowed}>
										{currentQuestion === questions.length - 1 ? 'Завершить' : 'Далее'}
									</Button>
								</div>
							</nav>
						)}
					</Form>
				</FormProvider>
			</div>
		) : (
			<InfoScreen screen="finishSection" />
		)
	) : (
		<InfoScreen screen="errorSection" />
	);
});
