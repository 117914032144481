import { useSWRConfig } from 'swr';
import { fetcher } from '@src/api/utils/fetcher';
import { Questionnaire } from '@models';
import { useCallback } from 'react';

interface ReturnData<T> {
	trigger: (id: string, data: T, optimisticMutate?: boolean) => Promise<T>;
}

export const useQuestionnaryPatch = <T extends Pick<Questionnaire, 'status'>>(): ReturnData<T> => {
	const { mutate } = useSWRConfig();

	const trigger = useCallback(
		async <T>(id: string, data: T, optimisticMutate?: boolean) => {
			if (optimisticMutate) {
				await mutate(
					`/questionnaires/`,
					() => {

						return {
							results: [data],
						};
					},
					false
				);
			}

			const response = await fetcher<T>({
				url: `/questionnaires/${id}/`,
				method: `PATCH`,
				body: data,
			});

			await mutate(`/questionnaires/${id}/`);

			return response;
		},
		[mutate]
	);

	return {
		trigger,
	};
};
