import React from 'react';
import { IRouteObject } from './router.types';
import { LayoutDefault } from '@components/Layout';
import { Error } from '@pages';
import {LayoutStart} from "@components/Layout/LayoutStart/LayoutStart";
import {LayoutForm} from "@components/Layout/LayoutForm/LayoutForm";
import {IndexPage} from "@components/IndexPage/IndexPage";
import {QuestionnaryForm} from "@components/QuestionnaryForm/QuestionnaryForm";

//TODO: убрать мусор
const routeConfig: IRouteObject[] = [
	{
		path: '/',
		private: true,
		element: <IndexPage/>,
		layout: LayoutStart,
		children: [
			{
				path: ':id',
				element: <></>,
				layout: LayoutDefault,
			},
		],
	},
	{
		path: '/questionnary',
		private: true,
		element: <QuestionnaryForm />,
		layout: LayoutForm,
		children: [
			{
				path: ':id',
				element: <></>,
				layout: LayoutDefault,
			},
		],
	},
	{
		path: '*',
		private: true,
		element: <Error />,
		layout: LayoutStart,
		title: 'Ошибка 404',
	},
	{
		path: '/404',
		private: true,
		element: <Error />,
		layout: LayoutStart,
		title: 'Ошибка 404',
	},
];

export default routeConfig;
