import useSWR from 'swr';
import { ReturnedSWRData } from '@api/hooks/types';
import { fetcher } from '@src/api/utils/fetcher';
import { PaginatedQuestionList } from '@models';

export const useQuestionList = (): ReturnedSWRData<PaginatedQuestionList> => {
	const { data, error } = useSWR(
			`/questionnaires/questions/`,
		(url: string) => fetcher<PaginatedQuestionList>(url),
		{
			revalidateIfStale: false,
			revalidateOnFocus: false,
			revalidateOnReconnect: true,
		}
	);

	return {
		data: data,
		isLoading: !error && !data,
		isError: error,
	};
};
