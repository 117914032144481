import { useSWRConfig } from 'swr';
import { PatchedExitAnswer } from '@models';
import { fetcher } from '@src/api/utils/fetcher';
import { useCallback } from 'react';

interface ReturnData<T> {
	trigger: (parent_lookup_questionnaire: string, data: Array<T> | T) => Promise<Array<T>>;
}

export const useAnswerPatch = <T extends PatchedExitAnswer>(): ReturnData<T> => {
	const { mutate } = useSWRConfig();

	const trigger = useCallback(
		async <T extends PatchedExitAnswer>(parent_lookup_questionnaire: string, data: Array<T> | T) => {
			data = Array.isArray(data) ? data : [data];

			const promiseList = data.map((answer) =>
				fetcher<T>({
					url: `/questionnaires/${parent_lookup_questionnaire}/answers/${answer.id}/`,
					method: `PATCH`,
					body: answer,
				})
			);

			const response = await Promise.all<T>(promiseList);

			await mutate(`/questionnaires/${parent_lookup_questionnaire}/answers/`);

			return response;
		},
		[mutate]
	);

	return {
		trigger,
	};
};
