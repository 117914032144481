import React, { FC, PropsWithChildren, useEffect, useRef, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import classnames from 'classnames';
import FocusTrap from 'focus-trap-react';
import { Portal, CloseButton } from '@src/gui';
import { useDelayUnmount } from '@hooks/useDelayUnmount';
import { useOutsideClick } from '@hooks/useOutsideClick';
import { useEscape } from '@hooks';
import scrollLocker from '@utils/utils.scroll/ScrollLocker';
import PopupsStore, { PopUpsNames } from '@store/Popups';

import './PopUp.scss';

// Usage:
// <PopUp active={popup} closeButton={true} closeHandler={closePopup} className="error-popup" />

interface Props {
	active?: boolean;
	name?: PopUpsNames;
	duration?: number;
	overlay?: boolean;
	boxWidth?: number;
	className?: string;
	label?: string;
	closeHandler?: (e?: MouseEvent | TouchEvent) => void;
	closeButton?: boolean;
}

export const PopUp: FC<PropsWithChildren<Props>> = observer(
	({
		active,
		name,
		duration = 400,
		overlay = true,
		boxWidth = 740,
		className,
		label = 'Модальное окно',
		closeHandler,
		closeButton = true,
		children,
	}) => {
		const storeState = name ? PopupsStore.store[name] : false;

		const boxRef = useRef<HTMLDivElement | null>(null);
		const isMount = active && !name ? active : name ? storeState : false;

		const shouldRender = useDelayUnmount(isMount, duration);
		const maxWidthCss = useMemo(() => ({ maxWidth: boxWidth }), [boxWidth]);

		useOutsideClick<HTMLDivElement | null>(boxRef, closeHandler, [], ['.curtain']);

		useEffect(() => {
			if (shouldRender) {
				scrollLocker.lock();
			} else {
				scrollLocker.unlock();
			}
		}, [shouldRender]);

		useEscape(shouldRender, () => {
			closeHandler && closeHandler();
		});

		const popup = useMemo(
			() => (
				<aside
					role="dialog"
					aria-modal="true"
					aria-label={label}
					className={classnames('popup', className, { 'is-shown': isMount })}
				>
					{overlay && <div className="popup__overlay" />}
					<div className="popup__capsule">
						<div className="popup__capsule-inner">
							<div className="popup__perspective" style={maxWidthCss}>
								<FocusTrap>
									<div className="popup__box" ref={boxRef} style={maxWidthCss}>
										{closeButton && closeHandler && (
											<CloseButton className="popup__close" onClick={closeHandler} />
										)}
										{children}
									</div>
								</FocusTrap>
							</div>
						</div>
					</div>
				</aside>
			),
			[boxRef, maxWidthCss, isMount, children, overlay, className, label, closeButton, closeHandler]
		);

		return shouldRender ? <Portal>{popup}</Portal> : null;
	}
);
