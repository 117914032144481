import React, { FC, useEffect, useMemo } from 'react';
import { IRouteMatch, IRouteObject } from '@router/router.types';
import { matchRoutes, useRoutes } from 'react-router-dom';
import { useLocation } from 'react-router';
import appRouteConfig from '@router/routeConfig';

import './assets/styles/app.scss';

const useProtectedRoute = (routeConfig: RouteObjectList) => {
	const location = useLocation();

	const route = useMemo(() => {
		const result = matchRoutes(routeConfig, location.pathname) as RouteMatchList;
		return result && result[0].route;
	}, [routeConfig, location]);

	const Page = useRoutes(routeConfig);
	const Layout = route.layout;

	return {
		Layout,
		Page,
		meta: {
			title: route.title,
		},
	};
};

export const App: FC = () => {
	const { Layout, Page, meta } = useProtectedRoute(appRouteConfig);

	useEffect(() => {
		document.title = `${meta?.title?.length ? `${meta.title} — ` : ``} R-Pharm «Exit-Interview»`;
	}, [meta?.title]);

	return <Layout meta={meta}>{Page}</Layout>;
};

type RouteObjectList = Array<IRouteObject>;
type RouteMatchList = Array<IRouteMatch>;
