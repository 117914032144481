import React from 'react';
import { Button, Spacer } from '@src/gui';

import './InfoScreen.scss';
import { useNavigate } from 'react-router';
import HashStore from '@store/HashStore';

export type Props = {
	id?: string;
	screen: string;
};

export const InfoScreen = ({ screen = 'sessionNotStarted', id = '' }: Props) => {
	const navigate = useNavigate();
	const startScreenApi = [
		{
			id: 'sessionNotStarted',
			title: 'Выходное интервью',
			description:
				'Мы&nbsp;сожалеем, что вы&nbsp;уходите из&nbsp;нашей компании и&nbsp;хотим понять причины вашего решения. Пожалуйста, ответьте на&nbsp;13&nbsp;вопросов анкеты, это займет не&nbsp;более 5&nbsp;минут.',
			btnLink: 'questionnary/' + HashStore.store.hash,
			btnText: 'Начать',
		},
		{
			id: 'sessionStarted',
			title: 'Мы&nbsp;сохранили ваши ответы',
			description:
				'Вы&nbsp;уже начинали заполнять анкету. Продолжите с&nbsp;того места, где&nbsp;остановились.',
			btnLink: 'questionnary/' + HashStore.store.hash,
			btnText: 'Продолжить',
		},
		{
			id: 'sessionComplete',
			title: 'Вы&nbsp;уже заполнили эту анкету',
			description: 'Отредактировать ее&nbsp;нельзя. Благодарим вас за&nbsp;прохождение интервью!',
		},
		{
			id: 'sessionExpired',
			title: 'Анкета больше не&nbsp;доступна',
			description: 'Время для заполнения анкеты вышло.',
		},
		{
			id: 'finishSection',
			title: 'Спасибо!',
			description:
				'Благодарим вас за&nbsp;прохождение выходного интервью! Мы&nbsp;сохранили и&nbsp;отправили ответы HR-специалисту.',
		},
		{
			id: 'errorSection',
			title: 'Страница не&nbsp;найдена',
			description: 'Ссылка на&nbsp;страницу не&nbsp;корректна или не&nbsp;действительна',
		},
	];

	return (
		<div className="finishWrap">
			<div className="wrap">
				{startScreenApi
					.filter((x) => x.id === screen)
					.map((item) => (
						<React.Fragment key={item.id}>
							<svg className="image">
								<use xlinkHref={'#' + item.id}></use>
							</svg>
							<h1 className="title" dangerouslySetInnerHTML={{ __html: item.title }} />
							<Spacer value={21} />
							<p className="description" dangerouslySetInnerHTML={{ __html: item.description }} />
							{item.btnLink && (
								<>
									<Spacer value={40} />
									<Button
										size="md"
										design="green"
										onClick={() => {
											navigate(`/${item.btnLink}`);
										}}>
										{item.btnText}
									</Button>
								</>
							)}
						</React.Fragment>
					))}
			</div>
		</div>
	);
};
