import React, {FC, PropsWithChildren, useCallback, useState, useMemo, useRef, Fragment} from 'react';
import classnames from 'classnames';
import AnimateHeight from 'react-animate-height';
import { useFormContext } from 'react-hook-form/dist/index.ie11';
import { FormControl } from '@src/gui/Form';
import { Skeleton } from '@src/gui';
import { AnswerText } from '@components/Answer/AnswerText';

import './Answer.scss';

interface AnswerProps {
	name: string;
	question: any;
	answers?: string[];
	hasError?: boolean;
	hasTextError?: boolean;
	isLoading?: boolean;
	isDisabled?: boolean;
}

export const Answer: FC<PropsWithChildren<AnswerProps>> = React.memo(
	({ name, question, answers, hasTextError, isLoading, isDisabled }) => {
		const currentAnswer = useMemo(() => {
			const answer = question?.answer;
			if (typeof answer === 'undefined' || answer === null) {
				return undefined;
			} else {
				return answer.answer;
			}
		}, [question?.answer]);

		const [checked, setChecked] = useState<string>(currentAnswer);
		const showTextareaRef = useRef<boolean>(question.customResponse)
		const textAreaText = useRef<string>(question?.answer?.answer)

		const { register } = useFormContext();
		const registerAnswer = !isLoading
			? register({
					required: {
						value: true,
						message: question.position,
					},
			  })
			: undefined;

		const changeHandler = useCallback((e) => {
			setChecked(e.target.value);
		}, []);

		if (isLoading) {
			return (
				<div className="answer">
					<div className="answer__group">
						<Skeleton width={124} height={40} margin="0" />
						<Skeleton width={124} height={40} margin="0" />
						<Skeleton width={124} height={40} margin="0" />
						<Skeleton width={124} height={40} margin="0" />
					</div>
				</div>
			);
		}

		return (
			<div className={classnames('answer')}>
				{
					showTextareaRef.current
						? <AnimateHeight
								key={name + question.position}
								duration={300}
								height={'auto'}
							>
								<AnswerText
									name={name + '-text'}
									position={question.position}
									value={showTextareaRef.current ? textAreaText.current : ''}
									description={question?.description}
									hasError={hasTextError}
									isDisabled={isDisabled}
									placeholder={question?.placeholder}
								/>
							</AnimateHeight>
						: <div
							className={'answer__group'}
							role="radiogroup"
							aria-labelledby={name}
							key={name}>
							{answers && answers?.map((thisAnswer: string) => {
								return <div key={thisAnswer} className={'answer__radio'}>
									<FormControl
										type="radio"
										name={
											(question?.otherAnswers ?? []).filter(((item: string[]) => (item[0] === thisAnswer))).length > 0
												? name + '-mixed'
												: name
										}
										value={thisAnswer}
										disabled={isDisabled}
										className="answer__item"
										ref={registerAnswer}
										onChange={changeHandler}
										checked={checked === thisAnswer}>{thisAnswer}</FormControl>

									{question?.otherAnswers?.map((item: string[]) => {
											return (thisAnswer === item[0])
												? <Fragment key={item[0] + question.position}>
														<AnimateHeight
															key={item[0] + question.position}
															duration={300}
															height={((checked === thisAnswer) ? 'auto' : 0)}
															className={'textarea-wrap'}
														>
															<AnswerText
																name={name + '-mixed'}
																position={question.position}
																value={(checked === thisAnswer && question.answer.answer === thisAnswer)
																	? question?.answer.otherAnswer : ''}
																description={question?.description}
																hasError={hasTextError}
																isDisabled={isDisabled}
																placeholder={item[1]}
																mixedQuestion={true}
																parent={thisAnswer}
															/>
														</AnimateHeight>
													</Fragment>
												: null
										})
									}
								</div>
							})}
						</div>
				}
			</div>
		);
	}
);
