/**
 * R-PHARM API
 * Your project description
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export enum DocumentTypeEnum {
	NUMBER_0 = 0,
	NUMBER_1 = 1,
	NUMBER_2 = 2,
	NUMBER_3 = 3,
	NUMBER_4 = 4,
	NUMBER_5 = 5,
}
