import React, { forwardRef, ComponentProps, PropsWithChildren } from 'react';

export interface FormProps extends ComponentProps<any> {
	onSubmit: React.FormEventHandler<HTMLFormElement>;
}

export const Form = forwardRef<HTMLFormElement, PropsWithChildren<FormProps>>(
	({ onSubmit, children, ...props }, ref): JSX.Element => {
		return (
			<form onSubmit={onSubmit} ref={ref} {...props}>
				{children}
			</form>
		);
	}
);
