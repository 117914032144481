import React, { FC } from 'react';

import './error.scss';
import {InfoScreen} from "@components/InfoScreen/InfoScreen";

export const Error: FC = () => {
	return (
		<InfoScreen screen="errorSection" />
	);
};
