import React, {
	FC,
	PropsWithChildren,
	MouseEvent,
	ReactNode,
	HTMLAttributes,
	useMemo,
	useCallback,
} from 'react';
import { NavLink } from 'react-router-dom';
import { Spinner } from '@src/gui';
import classnames from 'classnames';

import './Button.scss';

export type ButtonSizeType = 'xxs' | 'xs' | 'sm' | 'md' | 'lg';
export type ButtonDesignType = 'green' | 'green-light' | 'green-outline' | 'flat-green' | 'green-icon';

interface ButtonIconInterface {
	position: 'left' | 'right';
}

interface ButtonInterface extends HTMLAttributes<HTMLElement> {
	size?: ButtonSizeType;
	design?: ButtonDesignType;
	wide?: boolean;
	type?: 'button' | 'submit';
	className?: string;
	to?: string;
	href?: string;
	target?: '_blank';
	onClick?: (e: MouseEvent<HTMLElement>) => void;
	iconLeft?: ReactNode;
	iconRight?: ReactNode;
	isLoading?: boolean;
	disabled?: boolean;
}

interface ButtonContentInterface
	extends Pick<ButtonInterface, 'iconLeft' | 'iconRight' | 'isLoading'> {}

const ButtonIcon: FC<PropsWithChildren<ButtonIconInterface>> = ({ position, children }) => {
	return <div className={classnames(`btn__icon`, `btn__icon--${position}`)}>{children}</div>;
};

const ButtonContent: FC<PropsWithChildren<ButtonContentInterface>> = ({
	iconLeft,
	iconRight,
	isLoading,
	children,
}) => {
	return (
		<>
			{iconLeft && <ButtonIcon position="left">{iconLeft}</ButtonIcon>}
			<span>{children}</span>
			{iconRight && <ButtonIcon position="right">{iconRight}</ButtonIcon>}
			{isLoading && <Spinner type="ring" />}
		</>
	);
};

export const Button: FC<PropsWithChildren<ButtonInterface>> = ({
	size = '',
	design = '',
	type = 'button',
	wide,
	className,
	to,
	href,
	target,
	onClick,
	iconLeft,
	iconRight,
	isLoading,
	disabled,
	children,
	...props
}) => {
	const content = useMemo(
		() => (
			<ButtonContent iconLeft={iconLeft} iconRight={iconRight} isLoading={isLoading}>
				{children}
			</ButtonContent>
		),
		[iconLeft, iconRight, isLoading, children]
	);
	const classname = useMemo(() => {
		return classnames(
			`btn`,
			size && `btn--${size}`,
			design && `btn--${design}`,
			`btn--${wide ? `wide` : `inline`}`,
			{
				'is-loading': isLoading,
				'is-disabled': disabled,
			},
			className
		);
	}, [className, size, design, wide, isLoading, disabled]);

	const clickHandler = useCallback(
		(e) => {
			onClick && onClick(e);
		},
		[onClick]
	);

	if (to) {
		return (
			<NavLink className={classname} to={to} {...props}>
				{content}
			</NavLink>
		);
	}

	if (href) {
		return (
			<a
				className={classname}
				onClick={clickHandler}
				href={href}
				target={target}
				rel="noreferrer noopener"
				{...props}>
				{content}
			</a>
		);
	}

	return (
		<button type={type} onClick={clickHandler} className={classname} disabled={disabled} {...props}>
			{content}
		</button>
	);
};
