import useSWR from 'swr';
import { ReturnedSWRData } from '@api/hooks/types';
import { fetcher } from '@src/api/utils/fetcher';
import { PaginatedAnswerList } from '@models';
import { operations } from '@api/openapi/types';

type AnswerListParams = Partial<
	operations['questionnaires_answers_list']['parameters']['path']
> &
	Partial<operations['questionnaires_answers_list']['parameters']['query']>;

export const useAnswerList = ({
	parent_lookup_questionnaire,
}: AnswerListParams): ReturnedSWRData<PaginatedAnswerList> => {
	const answerId = parent_lookup_questionnaire;

	const { data, error } = useSWR(
		answerId ? `/questionnaires/${parent_lookup_questionnaire}/answers/` : null,
		(url: string) => fetcher<PaginatedAnswerList>(url),
		{
			revalidateIfStale: false,
			revalidateOnFocus: false,
			revalidateOnReconnect: true,
		}
	);

	return {
		data: data,
		isLoading: !!answerId && !error && !data,
		isError: !answerId || error,
	};
};
